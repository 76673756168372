import React from "react"

// components
import SEO from "../components/SEO"
import Hero from "../components/Hero"

import FamiliesGrid from "../components/FamiliesGrid"
import EmailSection from "../components/EmailSection"
import Subscribe from "../components/Subscribe"
import NewsCarousel from "../components/NewsCarousel"

const SponsorHome = args => {
  // Hacky way to check if the pageContext is a sponsor
  const sponsor = args.pageContext.hasOwnProperty("filter_criteria_needs_type")
    ? args.pageContext
    : null

  let carouselInfo

  if (sponsor) {
    const sponsorName = sponsor?.name || ""

    const sponsorNeeds = sponsor?.filter_criteria_needs_type
      ? JSON.parse(sponsor.filter_criteria_needs_type)
      : []

    const sponsorLocation = sponsor?.filter_criteria_geo
      ? JSON.parse(sponsor.filter_criteria_geo)
      : []

    const sponsorNeedsText = sponsorNeeds
      ?.map(n => n.toLowerCase())
      .join(", ")
      .replace(/, ([^,]*)$/, " and $1")

    const sponsorLocationText = sponsorLocation
      ?.map(location => location.split(",")[0])
      .join(", ")
      .replace(/, ([^,]*)$/, " and $1")

    carouselInfo = {
      title:
        sponsor?.landing_page_copy?.headline ||
        `Help a neighbor with ${sponsorName}`,
      subtitle:
        sponsor.landing_page_copy?.subheadline ||
        `Partnering with ${sponsorName} to support ${sponsorNeedsText} in ${sponsorLocationText}. Join us!`,
    }
  }

  const organizations = args.pageContext.hasOwnProperty("organization_id")
    ? [args.pageContext.organization_id]
    : args.pageContext.hasOwnProperty("organization_ids")
    ? args.pageContext.organization_ids
    : null

  const organizationText = args.pageContext.hasOwnProperty("organizationText")
    ? args.pageContext.organizationText
    : null

  return (
    <>
      <SEO />
      <Hero carouselInfo={carouselInfo} />
      <FamiliesGrid
        sponsor={sponsor}
        organizations={organizations}
        organizationText={organizationText}
      />
      <EmailSection />
      <NewsCarousel />
      <Subscribe />
    </>
  )
}

export default SponsorHome
